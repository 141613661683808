import {BASE_API_URL} from "./RestService";

export function getBudgetDetail(fetchData, id) {
    return fetchData(`${BASE_API_URL}/budget/${id}`, {});
}

export function updateBudgetDetail(fetchData, requestBody) {
    return updateBudgetDetailByJson(fetchData, JSON.stringify(requestBody));
}

export function updateBudgetDetailByJson(fetchData, jsonBody) {
    return fetchData(`${BASE_API_URL}/budget`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: jsonBody,
    });
}

export function duplicateBudgetDetail(fetchData, budgetDetailEndpoint, id) {
    return fetchData(`${BASE_API_URL}/${budgetDetailEndpoint}/duplicate/${id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });
}

export function deleteBudgetDetail(fetchData, budgetDetailEndpoint, id) {
    return fetchData(`${BASE_API_URL}/${budgetDetailEndpoint}/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });
}

export function updateFinancing(fetchData, financingPayment) {
    return fetchData(`${BASE_API_URL}/financing`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(financingPayment),
    });
}

export function updateTask(fetchData, task) {
    return fetchData(`${BASE_API_URL}/task`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(task),
    });
}

export function deleteTask(fetchData, taskId) {
    return fetchData(`${BASE_API_URL}/task/${taskId}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });
}
