import {getExpedientModel} from "../planning/ExpedientModel";
import {
    getAccommodationsModel,
    getActivitiesModel,
    getCarRentsModel,
    getExtrasModel,
    getFinancingsModel,
    getFlightsModel, getPeopleModel,
    getTransfersModel
} from "./detail/BudgetDetailModel";
import {getTechnicalModel} from "../common/CommonModel";

const budgetModel = {
    ...getTechnicalModel(),
    title: '',
    tripType: '',
    departureDate: '', // Only in BudgetList
    deliveryDate: '',
    status: '',
    assignedUserId: '',
    customers: '',
    expedient: {
        ...getExpedientModel(),
    },
    ...getFlightsModel(),
    ...getAccommodationsModel(),
    ...getTransfersModel(),
    ...getCarRentsModel(),
    ...getPeopleModel(),
    ...getActivitiesModel(),
    ...getFinancingsModel(),
    ...getExtrasModel(),
    incidents: [],
    tasks: [],
    payments: [],
    accounting: {
        ...getTechnicalModel(),
        price: '',
        pvp: '',
        pricePerFamily: '',
        pricePerFamilyEnabled: '',
        total: '',
        benefit: '',
    },
    lastUpdatedByAll: '',
    lastUpdatedTimestampAll: '',
};

export function getBudgetModel() {
    return budgetModel;
}

const tripTypeDictionary = {
    HONEY_MOON: 'LUNA DE MIEL',
    BIG_TRIP: 'GRAN VIAJE',
    VACATION: 'VACACIONAL',
};

export function getTripTypeDictionary() {
    return tripTypeDictionary;
}

const budgetStatus = {
    PENDING: 'PENDIENTE',
    SENT: 'ENVIADO',
    PENDING_REPLY: 'PENDIENTE DE RESPUESTA',
    APPOINTMENT: 'CITA',
    CLAIMED: 'RECLAMADO',
    SOLD: 'VENDIDO',
    DISCARDED: 'DESCARTADO',
    PENDING_CONTRACT: 'CONTRATO PENDIENTE',
    READY_TO_PLAN: 'LISTO PARA PLANIFICAR',
    READY_TO_DELIVER: 'LISTO PARA ENTREGAR',
    SCHEDULE_FOR_LATER: 'AGENDAR PARA MÁS ADELANTE',
    SERVICES_BLOCKED: 'SERVICIOS BLOQUEADOS',
    ARCHIVED: 'ARCHIVADO',
};

export function getBudgetStatus() {
    return budgetStatus;
}
